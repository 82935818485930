import * as ACTIONS from '../../store/actions/actions'

import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import { Context } from '../../store/Store'
import { Review } from './Review'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import axios from 'axios'

export const Form = () => {
  const [step, setStep] = useState(1)
  const { app, dispatch } = React.useContext(Context)
  const [loadingSubmit, setloadingSubmit] = React.useState(false)
  const [, setError] = React.useState(null)
  let history = useNavigate()

  const nextStep = () => setStep((prev) => prev + 1)
  const prevStep = () => setStep((prev) => prev - 1)

  const handleSubmit = () => {
    setloadingSubmit(true)
    let preparedData = {
      ...app.blsFormState.step1,
      choiceLegumes: [...app.blsFormState.step2.legumes],
      producteur: app.profil.certificat,
      producteur_adresse: `${app.profil.adresse} ${app.profil.code_postal} ${app.profil.ville}`,
      producteurId: app.profil.id,
    }

    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/bb/v1/bl2/`,
        preparedData
      )
      .then(function (response) {
        if (response.status === 200) {
          setloadingSubmit(false)
          setStep(1)
          dispatch(ACTIONS.setBlsFormState(null))
          history('/bls?success=create', { replace: true })
        } else {
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  switch (step) {
    case 1:
      return <Step1 nextStep={nextStep} />
    case 2:
      return <Step2 nextStep={nextStep} prevStep={prevStep} />
    case 3:
      return <Step3 nextStep={nextStep} prevStep={prevStep} />
    case 4:
      return (
        <Review
          prevStep={prevStep}
          onSubmit={handleSubmit}
          loadingSubmit={loadingSubmit}
        />
      )
    default:
      return null
  }
}
