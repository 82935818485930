//Store
import * as ACTIONS from '../store/actions/actions'

// K
import Bt from '../components/Bt'
import { Context } from '../store/Store'
import React from 'react'
import axios from 'axios'
// Thirds
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export function Login() {
  const { app, dispatch } = React.useContext(Context)
  const [error, setError] = React.useState(null)
  const [datas, setDatas] = React.useState(null)
  let history = useNavigate()

  React.useEffect(() => {
    if (datas !== null) {
      dispatch(ACTIONS.logued(datas))
      window.localStorage.setItem('user', JSON.stringify(datas))
    }
  }, [datas])

  const { register, handleSubmit } = useForm()
  const onSubmit = (data) => {
    axios
      .post(
        `${
          process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
        }/wp-json/jwt-auth/v1/token`,
        {
          username: data.username,
          password: data.password,
        }
      )
      .then(function (response) {
        if (response.data.statusCode === 200) {
          setDatas(response.data.data)
        } else {
          setError('Erreur de connexion, veuillez vérifier vos identifiants')
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  if (window.localStorage.getItem('user')) {
    document.location.reload()
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="w-9/12 mx-auto py-10">
        {error !== null && (
          <div className="p-5 bg-red-500 text-white">{error}</div>
        )}

        <div className="flex mx-auto mb-16 w-9/12 ">
          <img src="/images/logo-bb.png" />
        </div>

        <form
          className="mt-8 space-y-6"
          action="#"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Email address
              </label>
              <input
                id="username"
                name="username"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Identifiant"
                {...register('username', { required: true })}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                {...register('password', { required: true })}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <Bt title="Envoyer" type="submit" />
          </div>

          <p className="text-xs text-center text-gray-400">{`BIOBRIEZH webapp - Version : 2.1.6`}</p>
        </form>
      </div>
    </div>
  )
}
